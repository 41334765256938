<template>
  <div>
    <check-box-select
      :choices="youthHomes"
      :initial-value="selectedYouthHomes"
      @changed="onYouthHomeChanged($event)"
      :name-callback="getYouthHomeName"
      id="youthHomes-select"
    ></check-box-select>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { makeYouthHome } from '@/types/youth'
import { BackendApi } from '@/utils/http'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect'
import { existsIn } from '@/utils/arrays'

export default {
  name: 'YouthHomesMultipleSelector',
  components: { CheckBoxSelect, },
  mixins: [BackendMixin],
  props: {
    onlyNumbers: {
      type: Array,
      defaultValue: [],
    },
    initialIds: {
      type: Array,
      defaultValue: [],
    },
  },
  data() {
    return {
      selectedYouthHomes: [],
      youthHomes: [],
    }
  },
  created() {
    this.loadYouthHomes()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadYouthHomes() {
      const loadingName = 'youthHome'
      this.startLoading(loadingName)
      let url = '/api/youth/youth-homes/?active=1'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        let youthHomes = resp.data.map(elt => makeYouthHome(elt))
        if (this.onlyNumbers) {
          youthHomes = youthHomes.filter(
            elt => existsIn([elt.number], this.onlyNumbers)
          )
        }
        this.youthHomes = youthHomes
        if (this.initialIds.length) {
          this.selectedYouthHomes = youthHomes.filter(
            elt => existsIn([elt.id], this.initialIds)
          )
        } else {
          this.selectedYouthHomes = youthHomes
        }
        this.$emit('change', { youthHomes: this.selectedYouthHomes, all: true, initial: true, })
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(loadingName)
    },
    onYouthHomeChanged(event) {
      this.selectedYouthHomes = event.choices
      this.$emit('change', { youthHomes: this.selectedYouthHomes, all: event.all, initial: false, })
    },
    getYouthHomeName(elt) {
      return elt.name + ' ' + elt.schoolYear.name
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
