<template>
  <div class="search" v-if="hasPerm('families.view_family')">
    <vue-bootstrap-typeahead
      v-model="name"
      :data="families"
      placeholder="Entrez le nom d'une famille"
      ref="familyTypeahead"
      @hit="onTypeaheadHit"
      :minMatchingChars="3"
      :disableFiltering="true"
      :serializer="family => (family.entity.name + ': ' + family.entity.members)"
    >
      <template slot="append">
        <b-button @click="onClear" variant="secondary">
          <i class="fa fa-times"></i>
        </b-button>
      </template>
    </vue-bootstrap-typeahead>
  </div>
</template>

<script>
import _ from 'underscore'
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { makeFamily } from '@/types/families'
import { BackendApi } from '@/utils/http'

export default {
  name: 'FamilyTypeahead',
  mixins: [BackendMixin],
  props: {
    initialId: {
      type: Number,
      defaultValue: 0,
    },
  },
  data() {
    return {
      families: [],
      name: '',
    }
  },
  created() {
  },
  watch: {
    name: _.debounce(function(name) { this.searchFamily(name) }, 500),
    initialId: function(value) { this.initFamily(value) },
  },
  methods: {
    ...mapActions(['addError']),
    onClear(evt) {
      evt.preventDefault()
      this.name = ''
      this.families = []
      this.$refs.familyTypeahead.inputValue = ''
      this.$emit('change', { family: null, })
    },
    async searchFamily(text) {
      if (text) {
        try {
          let url = '/api/families/families/search_preview/?text=' + text
          const backendApi = new BackendApi('get', url)
          const resp = await backendApi.callApi()
          this.families = await resp.data.map(elt => makeFamily(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    onTypeaheadHit(evt) {
      this.$emit('change', { family: evt, })
    },
    async initFamily(familyId) {
      if (familyId) {
        try {
          let url = '/api/families/families/' + familyId + '/'
          const backendApi = new BackendApi('get', url)
          const resp = await backendApi.callApi()
          const family = makeFamily(resp.data)
          this.families = [family]
          this.$refs.familyTypeahead.inputValue = family.entity.name
          this.$emit('init', { family: family, })
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
