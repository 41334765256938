<template>
  <div>
    <check-box-select
      :choices="youthHomes"
      :initial-value="selectedYouthHomes"
      @changed="onYouthHomeChanged($event)"
      :name-callback="getYouthHomeName"
      id="youthHomes-select"
    ></check-box-select>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { makeYouthHome } from '@/types/youth'
import { BackendApi } from '@/utils/http'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect'
import { existsIn } from '@/utils/arrays'
import { makePortalService } from '@/types/portal'

export default {
  name: 'YouthHomesPortalSelector',
  components: { CheckBoxSelect, },
  mixins: [BackendMixin],
  props: {
    initialIds: {
      type: Array,
      defaultValue: [],
    },
  },
  data() {
    return {
      selectedYouthHomes: [],
      youthHomes: [],
      services: [],
    }
  },
  created() {
    this.init()
  },
  computed: {
  },
  watch: {},
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async init() {
      await this.loadServices()
      await this.loadYouthHomes()
    },
    async loadServices() {
      // TODO : refactor pour éviter de charger 2 fois les services (confirmation des inscriptions)
      // TODO: Attention aux effets de bords
      const url = '/portal/api/youth-home-services/'
      let backendApi = new BackendApi('get', url)
      try {
        let resp = await backendApi.callApi()
        this.services = resp.data.map(elt => makePortalService(elt))
        this.$emit('loaded', { services: this.services, })
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadYouthHomes() {
      const loadingName = 'youthHome'
      this.startLoading(loadingName)
      let url = '/api/youth/youth-homes/?active=1'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        const youthHomes = resp.data.map(makeYouthHome)
        const serviceNumbers = this.services.map(elt => elt.youthHomeNumber)
        this.youthHomes = youthHomes.filter(
          elt => elt.allowWaitingList || (serviceNumbers.indexOf(elt.number) >= 0)
        )
        this.selectedYouthHomes = this.youthHomes
        if (this.initialIds.length) {
          this.selectedYouthHomes = youthHomes.filter(
            elt => existsIn([elt.id], this.initialIds)
          )
        } else {
          this.selectedYouthHomes = youthHomes
        }
        this.$emit('change', { youthHomes: this.selectedYouthHomes, all: true, initial: true, })
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(loadingName)
    },
    onYouthHomeChanged(event) {
      this.selectedYouthHomes = event.choices
      this.$emit('change', { youthHomes: this.selectedYouthHomes, all: event.all, initial: false, })
    },
    getYouthHomeName(elt) {
      return elt.name + ' ' + elt.schoolYear.name
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
