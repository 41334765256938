<template>
  <div class="search" v-if="hasPerm('people.view_individual')">
    <vue-bootstrap-typeahead
      v-model="name"
      :data="individuals"
      placeholder="Entrez le nom de famille d'une personne"
      ref="individualTypeahead"
      @hit="onTypeaheadHit"
      :minMatchingChars="3"
      :disableFiltering="true"
      :serializer="individual => individual.firstAndLastName()"
    >
      <template slot="append">
        <b-button @click="onClear" variant="secondary">
          <i class="fa fa-times"></i>
        </b-button>
      </template>
    </vue-bootstrap-typeahead>
  </div>
</template>

<script>
import _ from 'underscore'
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { makeIndividual } from '@/types/people'
import { BackendApi } from '@/utils/http'

export default {
  name: 'IndividualTypeahead',
  mixins: [BackendMixin],
  props: {
    initialId: {
      type: Number,
      defaultValue: 0,
    },
  },
  data() {
    return {
      individuals: [],
      name: '',
    }
  },
  created() {
  },
  watch: {
    name: _.debounce(function(name) { this.searchIndividual(name) }, 500),
    initialId: function(value) { this.initIndividual(value) },
  },
  methods: {
    ...mapActions(['addError']),
    onClear(evt) {
      evt.preventDefault()
      this.name = ''
      this.individuals = []
      this.$refs.individualTypeahead.inputValue = ''
      this.$emit('change', { individual: null, })
    },
    async searchIndividual(text) {
      if (text) {
        try {
          let url = '/api/people/search-individual/?last_name=' + text
          const backendApi = new BackendApi('get', url)
          const resp = await backendApi.callApi()
          this.individuals = await resp.data.map(elt => makeIndividual(elt))
        } catch (err) {
          this.addError(this.getErrorText(err))
        }
      }
    },
    onTypeaheadHit(evt) {
      this.$emit('change', { individual: evt, })
    },
    async initIndividual(individualId) {
      if (individualId) {
        try {
          let url = '/api/people/individual/' + individualId + '/'
          const backendApi = new BackendApi('get', url)
          const resp = await backendApi.callApi()
          const individual = makeIndividual(resp.data)
          this.individuals = [individual]
          this.$refs.individualTypeahead.inputValue = individual.firstAndLastName()
          this.$emit('init', { individual: individual, })
        } catch (err) {
          this.addError(this.getErrorText(err))
        }
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
