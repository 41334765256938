<template>
  <div>
    <b-form-select
      id="period-select"
      v-model="period"
      @change="periodChanged()"
      >
      <b-form-select-option
        :value="period"
        v-for="period in periods"
        :key="period.id"
      >
        {{ period.name }}
      </b-form-select-option>
    </b-form-select>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { makeSeancePeriod } from '@/types/youth'
import { BackendApi } from '@/utils/http'
import { comparePeriods } from '@/utils/sorting'

export default {
  name: 'SeancePeriodsSelector',
  mixins: [BackendMixin],
  props: {
    initialId: {
      type: Number,
      defaultValue: 0,
    },
  },
  data() {
    return {
      period: null,
      periods: [],
    }
  },
  created() {
    this.loadPeriods()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadPeriods() {
      const periodsNames = 'periods'
      this.startLoading(periodsNames)
      let url = '/api/youth/seance-periods/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        let all = [makeSeancePeriod({ id: 0, name: 'Toutes les périodes', })]
        let periods = resp.data.map(elt => makeSeancePeriod(elt))
        periods = periods.sort((elt1, elt2) => comparePeriods(elt1, elt2))
        this.periods = all.concat(periods)
        this.periodsLoaded()
        if (this.initialId) {
          const initial = this.periods.filter(elt => elt.id === +this.initialId)
          if (initial.length) {
            this.period = initial[0]
          } else {
            this.period = all[0]
          }
        } else {
          this.period = all[0]
        }
        this.$emit('init', { period: this.period, })
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
      this.endLoading(periodsNames)
    },
    periodChanged() {
      this.$emit('change', { period: this.period, })
    },
    periodsLoaded() {
      this.$emit('loaded', { periods: this.periods, })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
